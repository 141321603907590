import IntegrationModal from "./integration";

const Modals = () => {
  return (
    <>
      <IntegrationModal />
    </>
  );
};

export default Modals;
