import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MdLogout } from "react-icons/md";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <button className="button__logout" onClick={handleLogout} style={{ fontSize: "14px" }}>
      Logout
      <MdLogout className="inline ml-2" />
    </button>
  );
};
