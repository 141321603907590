import { create } from "zustand";
import { persist } from "zustand/middleware";

const useDashboardStore = create((set) => ({
    isFetching: true,
    funnelData: null,
    leadAnalysisData: null,
    error: "",
    setIsFetching: async (isFetching) => {
        set((state) => ({ ...state, isFetching }));
    },
    setFunnelData: async (funnelData) => {
        set((state) => ({ ...state, funnelData }));
    },
    setLeadAnalysisData: async (leadAnalysisData) => {
        set((state) => ({ ...state, leadAnalysisData }));
    },
    setError: async (error) => {
        set((state) => ({ ...state, error }));
    },
}));

export default useDashboardStore;
