import axios from "axios";
import { API_URL } from "../config";
import useAuthStore from "./auth";

const instance = axios.create({
  baseURL: API_URL,
  timeout: 40000,
});

export default instance;

instance.interceptors.request.use(
  async (config) => {
    config.headers["Authorization"] = "Bearer " + useAuthStore.getState().accessToken
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Content-Type"] =
      config.headers["Content-Type"] ?? "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
