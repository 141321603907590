import { MODALS } from "../../types/modal.types";
import useAppStore from "../../zustand/app";
import ModalLayout from "../../layouts/modal";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useIntegration } from "../../hooks/integration";
import { useState } from "react";

const IntegrationModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const { integrateWithCRM } = useIntegration();
  const toggleModal = useAppStore((store) => store.toggleModal);
  const integrationModal = useAppStore(
    (store) => store.modal[MODALS.integration]
  );

  const onFormSubmit = (data) => {
    setIsLoading(true);
    const integrationId = integrationModal?.data?.id;

    if (!integrationId) return;

    integrateWithCRM(
      {
        ...data,
        state: "scaler:leadsquared",
      },
      integrationId
    )
      .then(() => {
        setIsLoading(false);
        toggleModal(MODALS.integration);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  if (!integrationModal?.isOpen) return;

  return (
    <ModalLayout
      action="Submit"
      title="Integration"
      isOpen
      isLoading={isLoading}
      closeModal={() => {
        toggleModal(MODALS.integration);
      }}
    >
      <form id="submit" onSubmit={handleSubmit(onFormSubmit)}>
        <FormControl id="access_key">
          <FormLabel>Access Key</FormLabel>
          <Input
            type="text"
            {...register("access_key", { required: true, minLength: 1 })}
            placeholder="access_key"
          />
        </FormControl>
        <FormControl id="secret_key">
          <FormLabel>Secret Key</FormLabel>
          <Input
            type="text"
            {...register("secret_key", { required: true, minLength: 1 })}
            placeholder="secret_key"
          />
        </FormControl>
      </form>
    </ModalLayout>
  );
};

export default IntegrationModal;
