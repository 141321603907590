import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdPeople,
  MdIntegrationInstructions,
} from "react-icons/md";

import Dashboard from "./pages/dashboard";
import Integration from "./pages/integration";
import Profile from "./pages/profile";
import Auth from "./pages/auth";
import Enrichment from "./pages/leads";

const routes = [
  {
    name: "Dashboard",
    layout: "main",
    path: "/",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Dashboard />,
  },
  {
    name: "Enrichment",
    layout: "main",
    path: "/enrichment",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: <Enrichment />,
  },
  {
    name: "Integrations",
    layout: "main",
    path: "/integrations",
    icon: (
      <Icon
        as={MdIntegrationInstructions}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <Integration />,
  },

  {
    name: "Auth",
    layout: "auth",
    path: "/auth",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Auth />,
  },
];

export default routes;
