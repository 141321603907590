import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AUTH0_DOMAIN,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH_CALLBACK_URL,
} from "./config";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const domain = AUTH0_DOMAIN;
  const clientId = AUTH0_CLIENT_ID;
  const redirectUri = AUTH_CALLBACK_URL;
  const audience = AUTH0_AUDIENCE;

  const onRedirectCallback = async (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  const authParams = {
    audience: audience,
    redirect_uri: redirectUri,
  }

  console.log(searchParams.get('invitation'), searchParams.get('organization'))
  if (searchParams.get('invitation')) {
    authParams.invitation = searchParams.get('invitation');
    authParams.organization = searchParams.get('organization');
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authParams}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
