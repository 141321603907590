import React from "react";
import {Box, Flex} from "@chakra-ui/react";
import { ColorRing } from 'react-loader-spinner'

export const PageLoader = () => {
  const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

  return (
    <Flex direction={{ base: "column" }} justify='center' className="page-loader">
      <Box mb={{ base: "20px", "2xl": "20px" }} position='relative'>
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#A9A9A9', '#A9A9A9', '#A9A9A9', '#A9A9A9', '#A9A9A9']}
        />
      </Box>
    </Flex>
  );
};
