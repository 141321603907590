const EmptyState = ({ style, children, className, variant }) => {
  variant = variant || 1;
  variant--;
  const illustrations = [
    "/MatchNotFound.svg",
    "/MatchNotFound2.svg",
    "/MatchNotFound3.svg",
  ];
  const illustrationSource = illustrations[variant];
  return (
    <div
      style={style}
      className={
        (className || "") +
        "w-full flex justify-center items-center flex-col rounded-lg bg-[#564e9208] p-5"
      }
    >
      <img
        src={illustrationSource}
        alt="Match Not Found"
        width={"250px"}
        className="mb-3 p-5"
      />
      <div className="text-2xl font-regular text-[#564e924b]">{children}</div>
    </div>
  );
};

export default EmptyState;
