import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";

const ModalLayout = ({
  isOpen,
  closeModal,
  title,
  action,
  onClick,
  isLoading,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isLoading}
            colorScheme="blue"
            form="submit"
            onClick={onClick}
          >
            {action}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalLayout;
