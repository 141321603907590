import { create } from "zustand";
import {persist} from "zustand/middleware";

const useAuthStore = create(
  persist(
    (set) => ({
      isAuthenticated: false,
      accessToken: null,
      user: null,
      login: async (accessToken, user) => {
        set({ isAuthenticated: true, accessToken, user });
      },
      logout: () => set({ isAuthenticated: false, accessToken: null, user: null }),
    }),
    {
      name: "userAuth",
      version: 1,
      migrate: (persistedState, version) => {
        if (version === 0) {
          return {
            ...persistedState
          };
        }
        return persistedState;
      },
    }
  )
);

export default useAuthStore;

