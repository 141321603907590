import { create } from "zustand";

//TODO: have to persist the state as well
const useAppStore = create((set, get) => ({
  modal: {}, // Record<keyof typeof MODALS, {isOpen: boolean, data: any}>,
  toggleModal: (id, data) => {
    const modal = get().modal;
    console.log("Toggle", id, data);
    set({
      modal: {
        ...modal,
        [id]: {
          isOpen: !modal[id]?.isOpen,
          data: data,
        },
      },
    });
  },
}));

export default useAppStore;
