import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import Sidebar, { SidebarResponsive } from "../../components/sidebar/Sidebar";
import { SidebarContext } from "../../components/contexts/SidebarContext";
import { Redirect, Route, Routes,useSearchParams } from "react-router-dom";
import routes from "../../routes.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import useAuthStore from "../../zustand/auth";

const MainLayout = (props) => {
  const { ...rest } = props;
  const login = useAuthStore((state) => state.login);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { user, getAccessTokenSilently, logout } = useAuth0();
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        await login(token, user);
        return token;
      } catch (err) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
        console.error(err);
      }
    };
    getToken();
    	if (searchParams.has('invitation')) {
			searchParams.delete('invitation');
			searchParams.delete('organization');
			searchParams.delete('organization_name');
			setSearchParams(searchParams);
		}
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "main") {
        return <Route path={prop.path} element={prop.component} key={key} />;
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={routes} display="none" {...rest} />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{ base: "100%", xl: "calc( 100% - 290px )" }}
            maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Box
              mx="auto"
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              pt="50px"
              background="#F0F8FF"
            >
              <Routes>{getRoutes(routes)}</Routes>
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
};

export default MainLayout;
