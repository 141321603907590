import React, { useRef } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { columnsData, tableData } from "./data/columnsData";
import ColumnsTable from "../components/tables/ColumnsTable";
import { useEnrichment } from "../hooks/enrichment";
import { useQuery } from "@tanstack/react-query";
import { DownloadIcon } from "@chakra-ui/icons";

const Leads = (props) => {
  const { getAllFiles, uploadFile, getFileDownloadLink } = useEnrichment();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["enrichment-files"],
    queryFn: getAllFiles,
  });

  console.log(data)
  const ref = useRef();
  const handleClick = (e) => {
    ref.current?.click();
  };

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  let mainText = useColorModeValue("navy.700", "white");

  const handleDownload = (e) => {
    const fileId = e.target
      .closest("td[data-file-id]")
      .getAttribute("data-file-id");
    if (fileId) {
      getFileDownloadLink(fileId).then((link) => {
        if (!link) return;

        const anchor = document.createElement("a");
        anchor.href = link;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      });
    }
  };

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "20px" }}>
      <Flex align="center" justify="space-between">
        <Link
          color={mainText}
          href="#"
          bg="inherit"
          borderRadius="inherit"
          fontWeight="bold"
          fontSize="34px"
          _hover={{ color: { mainText } }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
        >
          Enrichment
        </Link>
        <Button
          onClick={handleClick}
          variant="darkBrand"
          colorScheme="green"
          fontSize="sm"
          fontWeight="500"
          borderRadius="70px"
          px="24px"
          py="5px"
        >
          Upload CSV
        </Button>
        <input
          ref={ref}
          type="file"
          accept=".csv"
          onChange={uploadFile}
          style={{ display: "none" }}
        />
      </Flex>
      <Grid
        mb="20px"
        mt="20px"
        templateColumns="repeat(4, 1fr)"
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <GridItem colSpan={4}>
          <ColumnsTable
            actions={[
              {
                label: "download",
                icon: <DownloadIcon />,
                onClick: (e) => {
                  handleDownload(e);
                },
              },
            ]}
            columnsData={columnsData}
            tableData={data ?? []}
            title="All"
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Leads;
