import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, {useEffect} from "react";
import { PageLoader } from "./pageLoader";

export const AuthenticationGuard = ({ component }) => {
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    // const getToken = async () => {
    //   const token = await getAccessTokenSilently()
    //   console.log(token);
    //   return token;
    // }
    // getToken();
    console.log('isAuthenticated: ', isAuthenticated);
  }, [])

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component />;
};
