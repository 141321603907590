import { Box, Flex, Stack } from "@chakra-ui/react";

import Brand from "./Brand";
import Links from "./Links";
import React from "react";
import { LogoutButton } from "../../../components/logoutButton.jsx";

function SidebarContent(props) {
  const { routes } = props;

  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      px="16px"
      borderRadius="30px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px" flex={1}>
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Flex ps="20px" pe={{ md: "16px", "2xl": "1px" }} pb="40px" justify="end">
        <Box
          justify="center"
          align="center"
          borderRadius="full"
          overflow="hidden"
          transition="all 0.3s"
          width="7rem"
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "md",
          }}
        >
          <LogoutButton />
        </Box>
      </Flex>
    </Flex>
  );
}

export default SidebarContent;
