import React from "react";

import {Flex, Image, useColorModeValue} from "@chakra-ui/react";

import RevSpotLogo from "../../../assets/revspot.png";
import { HSeparator } from "../../separator/Separator";

export function SidebarBrand() {
  return (
    <Flex align='center' direction='column'>
      <Image h='36px' w='130px' my="10px" src={RevSpotLogo} />
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
