export const columnsData = [
  {
    Header: "FILE",
    accessor: "file_name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "LEADS",
    accessor: "lead_count",
  },
  {
    Header: "CREATED AT",
    accessor: "created_at",
  },
  {
    Header: "ACTIONS",
    accessor: "actions",
  },
];

export const tableData = [
  {
    email: "chirag@revspot.ai",
    enriched: "true",
    prof_level: "Executive",
    location_type: "international",
    rql: "true",
  },
  {
    email: "chirag@revspot.ai",
    enriched: "true",
    prof_level: "Management",
    location_type: "revspot_city",
    rql: "true",
  },
  {
    email: "anil@revspot.ai",
    enriched: "true",
    prof_level: "Senior",
    location_type: "other_metro",
    rql: "true",
  },
  {
    email: "varun@revspot.ai",
    enriched: "true",
    prof_level: "Junior",
    location_type: "other",
    rql: "false",
  },
  {
    email: "tj@revspot.ai",
    enriched: "false",
    prof_level: "",
    location_type: "",
    rql: "false",
  },
];

export const rqlColumnsData = [
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "PROFESSIONAL LEVEL",
    accessor: "prof_level",
  },
  {
    Header: "LOCATION TYPE",
    accessor: "location_type",
  },
  {
    Header: "STAGE",
    accessor: "stage",
  },
];

export const rqlTableData = [
  {
    email: "chirag@revspot.ai",
    prof_level: "Executive",
    location_type: "international",
    stage: "Converted",
  },
  {
    email: "chirag@revspot.ai",
    prof_level: "Management",
    location_type: "revspot_city",
    stage: "Lost",
  },
  {
    email: "anil@revspot.ai",
    prof_level: "Senior",
    location_type: "other_metro",
    stage: "Converted",
  },
];
