import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import MainLayout from "./layouts/main";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { Auth0ProviderWithNavigate } from "./authProvider";
import Auth from "./pages/auth";
import { CallbackPage } from "./pages/callback";
import { AuthenticationGuard } from "./components/authenticationGuard";
import { IntegrationsCallbackPage } from "./pages/integrationsCallback";
import Modals from "./components/modals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Modals />
        <Auth0ProviderWithNavigate>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route
                path="/integrations/callback"
                element={<IntegrationsCallbackPage />}
              />
              <Route path="/callback" element={<CallbackPage />} />
              <Route path="/auth" element={<Auth />} />
              <Route
                path="*"
                element={<AuthenticationGuard component={MainLayout} />}
              />
            </Routes>
          </QueryClientProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
