import React, { useEffect } from "react";
import useDashboardStore from "../zustand/dashboard";
import axios from "../zustand/utils";
import useAuthStore from "../zustand/auth";

const useDashboardData = ({ startDate, endDate }) => {
    const {
        isFetching,
        funnelData,
        leadAnalysisData,
        error,
        setIsFetching,
        setFunnelData,
        setLeadAnalysisData,
        setError,
    } = useDashboardStore((state) => state);
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            try {
                const funnelDataConfig = {
                    method: "post",
                    url: "/metrics/funnel",
                    data: {
                        start_date: startDate,
                        end_date: endDate,
                    },
                };

                const funnelDataResponse = await axios(funnelDataConfig);
                if (funnelDataResponse.data.success) {
                    setFunnelData(funnelDataResponse.data.data);
                }

                const leadAnalysisConfig = {
                    method: "post",
                    url: "/metrics/lead-analysis",
                    data: {
                        start_date: "2024-01-01T00:00:00.000000+00:00",
                        end_date: "2024-07-01T00:00:00.000000+00:00",
                    },
                };

                const leadAnalysisResponse = await axios(leadAnalysisConfig);
                if (leadAnalysisResponse.data.success) {
                    setLeadAnalysisData(leadAnalysisResponse.data.data);
                }
            } catch (error) {
                setError(error.message);
            }
            setIsFetching(false);
        };

        if (isAuthenticated) {
            fetchData();
        }
    }, [isAuthenticated, startDate, endDate]);

    return {
        isFetching,
        funnelData,
        leadAnalysisData,
        error,
    };
};

export default useDashboardData;
